function addMarkup(title, textEU, notes) {
  // For touch devices
  // var markup = '<div class="shipping-info-container"><div class="shipping-info-inner"><div class="shipping-info-wrapper"><h4>' + title + '</h4><p>' + textEU + '</p><p>' + textWorld + '</p><p class="notes">' + notes + '</p></div></div></div>';
  var markup =
    '<div class="shipping-info-container"><div class="shipping-info-inner"><div class="shipping-info-wrapper"><div id="custom-shipping-info"><h4>' +
    title +
    '</h4><p class="text">' +
    textEU +
    '</p><p class="notes">' +
    notes +
    "</p></div></div></div></div>";
  return markup;
}

function initHoverStates() {
  var titleGLS = "GLS (Pakkeshop)";
  var standardGLS = "Delivery time: 2-5 business days";

  var titleDHLEc = "DHL ECONOMY";
  var standardDHLEc = "We dispatch all orders within 1-3 business days.";

  var titleDHLEx = "DHL EXPRESS";
  var standardDHLEx =
    "Denmark: 1-2 business days<br>Europe 1-2 business days<br>Rest of the world: 2-3 business days";

  var titleStandardHome = "Standard Home Delivery (UPS/FedEx)";
  var standardStandardHome =
    "Europe EU: 4-6 business days<br>Rest of Europe: 4-8 business days<br>United Kingdom: 4-6 business days<br>USA & Canada: 4-8 business days<br>Rest of the world: 6-8 business days";

  var titleDHLEx = "DHL EXPRESS";
  var standardDHLEx =
    "Denmark: 1-2 business days<br>Europe 1-2 business days<br>Rest of the world: 2-3 business days";

  var notesGLS = "(Delivered to the nearest GLS Pakkeshop)";
  var notesDHL =
    "Note: Some countries, remote cities or islands may have longer delivery times. Please get in touch if you need a more exact delivery time for your country/city.";

  var titleStandard = "Standard Shipping";
  var standardTextEU = "Europe: 2-6 business days";
  var standardTextWorld = "Rest of the world: 5-12 business days*";

  var titleExpress = "Express shipping";
  var expressTextEU = "Europe: 1-2 business days";
  var expressTextWorld = "Rest of the world: 2-3 business days*";
  var notes =
    "* Some countries, remote cities or islands may have longer delivery times. Please get in touch if you need a more exact delivery time for your country/city.";

  var titleShipping = "Shipping";
  var shippingTextEU = "Europe: 1-3 business days";
  var shippingTextWorld = "Rest of the world: 2-4 business days*";
  var notes =
    "* Some countries, remote cities or islands may have longer delivery times. Please get in touch if you need a more exact delivery time for your country/city.";

  var expressTitle = "Express Home Delivery (UPS/FedEx)";
  var expressText =
    "Europe EU: 1-2 business days<br>Rest of Europe: 2-3 business days<br>United Kingdom: 1-2 business days<br>USA & Canada: 3-5 business days<br>Rest of the world: 3-5 business days";

  var container = jQuery("#shipping_method");

  var GLS = jQuery(".info-label-gls .shipping-info-icon");
  var GLSSingle = jQuery(".info-label-gls").siblings(".shipping-info-icon");
  var GLSContainer = jQuery(".info-label-gls");
  var DHLEc = jQuery(".info-label-dhl-economy .shipping-info-icon");
  var DHLEcContainer = jQuery(".info-label-dhl-economy");
  var DHLEx = jQuery(".info-label-dhl-express .shipping-info-icon");
  var DHLExContainer = jQuery(".info-label-dhl-express");

  // prettier-ignore
  var fedexStandard = jQuery(".info-label-standard-\\(fedex-home-delivery\\) .shipping-info-icon");
  var fedexStandardSingle = jQuery(
    ".info-label-standard-\\(fedex-home-delivery\\)"
  ).siblings(".shipping-info-icon");
  // prettier-ignore
  var fedexStandardContainer = jQuery(".info-label-standard-\\(fedex-home-delivery\\)");

  // prettier-ignore
  var fedexExpress = jQuery(".info-label-express-\\(fedex-home-delivery\\) .shipping-info-icon");
  var fedexExpressSingle = jQuery(
    ".info-label-express-\\(fedex-home-delivery\\)"
  ).siblings(".shipping-info-icon");
  // prettier-ignore
  var fedexExpressContainer = jQuery(".info-label-express-\\(fedex-home-delivery\\)");
  // prettier-ignore
  var upsStandard = jQuery(".info-label-standard-\\(ups-home-delivery\\) .shipping-info-icon");
  var upsStandardSingle = jQuery(
    ".info-label-standard-\\(ups-home-delivery\\)"
  ).siblings(".shipping-info-icon");
  // prettier-ignore
  var upsStandardContainer = jQuery(".info-label-standard-\\(ups-home-delivery\\)");
  // prettier-ignore
  var upsExpress = jQuery(".info-label-express-\\(ups-home-delivery\\) .shipping-info-icon");
  var upsExpressSingle = jQuery(
    ".info-label-express-\\(ups-home-delivery\\)"
  ).siblings(".shipping-info-icon");
  // prettier-ignore
  var upsExpressContainer = jQuery(".info-label-express-\\(ups-home-delivery\\)");

  if (container.is(":visible")) {
    // prettier-ignore
    var standard = jQuery(".info-label-free-shipping .shipping-info-icon, .info-label-standard .shipping-info-icon");
    // prettier-ignore
    var standardContainer = jQuery(".info-label-free-shipping, .info-label-standard");
    // prettier-ignore
    var express = jQuery(".info-label-express .shipping-info-icon");
    // prettier-ignore
    var expressContainer = jQuery(".info-label-express");
  } else {
    var expressSingle = jQuery(".info-label-free-express .shipping-info-icon");
    var expressSingleContainer = jQuery(".info-label-free-express");

    if (jQuery(".info-label-shipping").length) {
      var frames = jQuery(".info-label-shipping .shipping-info-icon");
      var framesContainer = jQuery(".info-label-shipping");
    } else if (jQuery(".info-label-frame-shipping").length) {
      var frames = jQuery(".info-label-frame-shipping .shipping-info-icon");
      var framesContainer = jQuery(".info-label-frame-shipping");
    } else {
      // prettier-ignore
      var frames = jQuery(".info-label-standard-with-frames .shipping-info-icon");
      var framesContainer = jQuery(".info-label-standard-with-frames");
    }
  }

  if (!jQuery("html").hasClass("touchevents")) {
    if (GLS) {
      // jQuery(GLS).hover(function(e) {
      //     if (!container) {
      //         container = jQuery('#order_review');
      //     }
      //     var infoElem = jQuery(container).append('<div id="custom-shipping-info"><h4>' + titleGLS + '</h4><p class="text">' + standardGLS + '</p><p class="notes">' + notesGLS + '</p></div>');
      // }, function(e) {
      //     var info = jQuery('#custom-shipping-info');
      //     jQuery(info).remove();
      // });

      jQuery(GLS)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(GLSContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              titleGLS +
              '</h4><p class="text">' +
              standardGLS +
              '</p><p class="notes">' +
              notesGLS +
              "</p></div>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }

    if (GLSSingle) {
      jQuery(GLSSingle)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(GLSContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              titleGLS +
              '</h4><p class="text">' +
              standardGLS +
              '</p><p class="notes">' +
              notesGLS +
              "</p></div>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }

    if (fedexStandard) {
      jQuery(fedexStandard)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(fedexStandardContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              titleStandardHome +
              '</h4><p class="text">' +
              standardStandardHome +
              "</p>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }

    if (fedexStandardSingle) {
      jQuery(fedexStandardSingle)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(fedexStandardContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              titleStandardHome +
              '</h4><p class="text">' +
              standardStandardHome +
              "</p>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }

    if (fedexExpress) {
      jQuery(fedexExpress)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(fedexExpressContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              expressTitle +
              '</h4><p class="text">' +
              expressText +
              "</p>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }

    if (fedexExpressSingle) {
      jQuery(fedexExpressSingle)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(fedexExpressContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              expressTitle +
              '</h4><p class="text">' +
              expressText +
              "</p>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }

    if (upsStandard) {
      jQuery(upsStandard)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(upsStandardContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              titleStandardHome +
              '</h4><p class="text">' +
              standardStandardHome +
              "</p>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }

    if (upsStandardSingle) {
      jQuery(upsStandardSingle)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(upsStandardContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              titleStandardHome +
              '</h4><p class="text">' +
              standardStandardHome +
              "</p>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }

    if (upsExpress) {
      jQuery(upsExpress)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(upsExpressContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              expressTitle +
              '</h4><p class="text">' +
              expressText +
              "</p>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }

    if (upsExpressSingle) {
      jQuery(upsExpressSingle)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(upsExpressContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              expressTitle +
              '</h4><p class="text">' +
              expressText +
              "</p>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }

    if (DHLEc) {
      // jQuery(DHLEc).hover(function(e) {
      //     if (!container) {
      //         container = jQuery('#order_review');
      //     }
      //     var infoElem = jQuery(container).append('<div id="custom-shipping-info"><h4>' + titleDHLEc + '</h4><p class="text">' + standardDHLEc + '</p><p class="notes">' + notesDHL + '</p></div>');
      // }, function(e) {
      //     var info = jQuery('#custom-shipping-info');
      //     jQuery(info).remove();
      // });

      jQuery(DHLEc)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(DHLEcContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              titleDHLEc +
              '</h4><p class="text">' +
              standardDHLEc +
              '</p><p class="notes">' +
              notesDHL +
              "</p></div>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }

    if (DHLEx) {
      // jQuery(DHLEx).hover(function(e) {
      //     if (!container || container.length === 0) {
      //         container = jQuery('#order_review');
      //     }
      //     var infoElem = jQuery(container).append('<div id="custom-shipping-info"><h4>' + titleDHLEx + '</h4><p class="text">' + standardDHLEx + '</p><p class="notes">' + notesDHL + '</p></div>');
      // }, function(e) {
      //     var info = jQuery('#custom-shipping-info');
      //     jQuery(info).remove();
      // });

      jQuery(DHLEx)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(DHLExContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              titleDHLEx +
              '</h4><p class="text">' +
              standardDHLEx +
              '</p><p class="notes">' +
              notesDHL +
              "</p></div>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }
    if (standard) {
      // jQuery(standard).hover(function(e) {
      //     console.log('add standard');
      //     var infoElem = jQuery(container).append('<div id="custom-shipping-info"><h4>' + titleStandard + '</h4><p class="text">' + standardTextEU + '</p><p class="text">' + standardTextWorld + '</p><p class="notes">' + notes + '</p></div>');
      // }, function(e) {
      //     console.log('remove standard');
      //     var info = jQuery('#custom-shipping-info');
      //     console.log('info', info);
      //     jQuery(info).remove();
      // });
      jQuery(standard)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(standardContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              titleStandard +
              '</h4><p class="text">' +
              standardTextEU +
              '</p><p class="text">' +
              standardTextWorld +
              '</p><p class="notes">' +
              notes +
              "</p></div>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }
    if (express) {
      // jQuery(express).hover(function(e) {
      //     console.log('add express');
      //     var infoElem = jQuery(container).append('<div id="custom-shipping-info"><h4>' + titleExpress + '</h4><p class="text">' + expressTextEU + '</p><p class="text">' + expressTextWorld + '</p><p class="notes">' + notes + '</p></div>');
      // }, function(e) {
      //     console.log('remove express');
      //     var info = jQuery('#custom-shipping-info');
      //     jQuery(info).remove();
      // });

      jQuery(express)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(expressContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              titleExpress +
              '</h4><p class="text">' +
              expressTextEU +
              '</p><p class="text">' +
              expressTextWorld +
              '</p><p class="notes">' +
              notes +
              "</p></div>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }
    if (expressSingle) {
      // jQuery(express_single).hover(function(e) {
      //     var infoElem = jQuery(express_single).append('<div id="custom-shipping-info"><h4>' + titleExpress + '</h4><p class="text">' + expressTextEU + '</p><p class="text">' + expressTextWorld + '</p><p class="notes">' + notes + '</p></div>');
      // }, function(e) {
      //     var info = jQuery('#custom-shipping-info');
      //     jQuery(info).remove();
      // });

      jQuery(expressSingle)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(expressSingleContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              titleExpress +
              '</h4><p class="text">' +
              expressTextEU +
              '</p><p class="text">' +
              expressTextWorld +
              '</p><p class="notes">' +
              notes +
              "</p></div>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }
    if (frames) {
      // jQuery(frames).hover(function(e) {
      //     var infoElem = jQuery(frames).append('<div id="custom-shipping-info"><h4>' + titleShipping + '</h4><p class="text">' + shippingTextEU + '</p><p class="text">' + shippingTextWorld + '</p><p class="notes">' + notes + '</p></div>');
      // }, function(e) {
      //     var info = jQuery('#custom-shipping-info');
      //     jQuery(info).remove();
      // });

      jQuery(frames)
        .on("mouseenter", () => {
          console.log("add express");
          jQuery(framesContainer).append(
            '<div id="custom-shipping-info"><h4>' +
              titleShipping +
              '</h4><p class="text">' +
              shippingTextEU +
              '</p><p class="text">' +
              shippingTextWorld +
              '</p><p class="notes">' +
              notes +
              "</p></div>"
          );
        })
        .on("mouseleave", () => {
          console.log("remove express");
          const info = jQuery("#custom-shipping-info");
          if (info) {
            jQuery(info).remove();
          }
        });
    }
  } else {
    if (standard) {
      jQuery("label", standard).on("click", function (e) {
        //   e.preventDefault();
        var infobox = addMarkup(
          titleStandard,
          standardTextEU,
          standardTextWorld,
          notes
        );
        jQuery("body").append(infobox);
        jQuery(".shipping-info-container").on("click", function (e) {
          //console.log('clicking new info box, remove it!', e);
          jQuery(".shipping-info-container").remove();
        });
      });
    }

    if (GLS) {
      jQuery(GLS).on("click", function (e) {
        // e.preventDefault();
        var infobox = addMarkup(titleGLS, standardGLS, notesGLS);
        jQuery("body").append(infobox);
        jQuery(".shipping-info-container").on("click", function (e) {
          //console.log('clicking new info box, remove it!', e);
          jQuery(".shipping-info-container").remove();
        });
      });
    }

    if (DHLEc) {
      jQuery(DHLEc).on("click", function (e) {
        // e.preventDefault();
        var infobox = addMarkup(titleDHLEc, standardDHLEc, notesDHL);
        jQuery("body").append(infobox);
        jQuery(".shipping-info-container").on("click", function (e) {
          //console.log('clicking new info box, remove it!', e);
          jQuery(".shipping-info-container").remove();
        });
      });
    }

    if (DHLEx) {
      jQuery(DHLEx).on("click", function (e) {
        // e.preventDefault();
        var infobox = addMarkup(titleDHLEx, standardDHLEx, notesDHL);
        jQuery("body").append(infobox);
        jQuery(".shipping-info-container").on("click", function (e) {
          //console.log('clicking new info box, remove it!', e);
          jQuery(".shipping-info-container").remove();
        });
      });
    }

    if (express) {
      jQuery("label", express).on("click", function (e) {
        // e.preventDefault();
        var infobox = addMarkup(
          titleExpress,
          expressTextEU,
          expressTextWorld,
          notes
        );
        jQuery("body").append(infobox);
        jQuery(".shipping-info-container").on("click", function (e) {
          //console.log('clicking new info box, remove it!', e);
          jQuery(".shipping-info-container").remove();
        });
      });
    }
    if (expressSingle) {
      jQuery(expressSingle).on("click", function (e) {
        // e.preventDefault();
        var infobox = addMarkup(
          titleExpress,
          expressTextEU,
          expressTextWorld,
          notes
        );
        jQuery("body").append(infobox);
        jQuery(".shipping-info-container").on("click", function (e) {
          //console.log('clicking new info box, remove it!', e);
          jQuery(".shipping-info-container").remove();
        });
      });
    }

    if (frames) {
      jQuery(frames).on("click", function (e) {
        // e.preventDefault();
        var infobox = addMarkup(
          titleShipping,
          shippingTextEU,
          shippingTextWorld,
          notes
        );
        jQuery("body").append(infobox);
        jQuery(".shipping-info-container").on("click", function (e) {
          //console.log('clicking new info box, remove it!', e);
          jQuery(".shipping-info-container").remove();
        });
      });
    }
  }
}

function shippingHover() {
  jQuery("body").on("updated_cart_totals", function (e) {
    //console.log('updated shipping method');
    initHoverStates();
  });

  jQuery("body").on("updated_checkout", function (e) {
    // console.log('updated checkout');
    initHoverStates();
  });

  jQuery(window).load(function () {
    if (
      jQuery("body").hasClass("woocommerce-cart") ||
      jQuery("body").hasClass("woocommerce-checkout")
    ) {
      initHoverStates();
    }
  });
}

export default shippingHover;
